import { Box, Typography } from "@mui/material";
import React from "react";
import { category, code } from "../types";

interface IProps {
  codes: { [index in category]: code[] };
}

export default function ReservationSummary ({ codes }: IProps) {
  const { rec, med, vet } = codes;

  const recCodes = rec.filter((c) => c.ammount > 0 && c.code.length > 0);
  const medCodes = med.filter((c) => c.ammount > 0 && c.code.length > 0);
  const vetCodes = vet.filter((c) => c.ammount > 0 && c.code.length > 0);

  return (
    <>
      {(recCodes.length > 0 || medCodes.length > 0 || vetCodes.length > 0) && (
        <>
          <br />
          <br />
          <Typography variant="h5">Shrnutí rezervace</Typography>

          <Box width="100%" alignContent="left">
            {recCodes.length > 0 && (
              <>
                <Typography variant="h6">eRecepty</Typography>
                {recCodes.map((c, i) => (
                  <Typography key={i} variant="body1">
                    ({c.ammount}x) {c.code}
                  </Typography>
                ))}
                <br />
                <br />
              </>
            )}
            {medCodes.length > 0 && (
              <>
                <Typography variant="h6">Volně prodejné produkty</Typography>
                {medCodes.map((c, i) => (
                  <Typography key={i} variant="body1">
                    ({c.ammount}x) {c.code}
                  </Typography>
                ))}
                <br />
                <br />
              </>
            )}
            {vetCodes.length > 0 && (
              <>
                <Typography variant="h6">Veterinární přípravky</Typography>
                {vetCodes.map((c, i) => (
                  <Typography key={i} variant="body1">
                    ({c.ammount}x) {c.code}
                  </Typography>
                ))}
                <br />
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
}

import React from "react";
import { Button, Container, Typography } from "@mui/material";

export default function ReservationCreatedConfirm () {
  return (
    <Container maxWidth="lg">
      <img
        src="https://magistrmartin.cz/img/NewReservationImage.webp"
        style={{ width: "80%", marginLeft: "10%" }}
        alt="orderReceivedBanner"
      />
      <br />
      <br />
      <br />
      <Container maxWidth="sm">
        <Typography variant="h5" align="center">
          Rezervace byla úspěšně odeslána
        </Typography>
        <br />
        <br />
        <Typography align="justify">
          Jakmile bude vaše rezervace eReceptu připravena k vyzvednutí, zašleme vám email s bližšími informacemi. Děkujeme. Tým
          rodinných lékáren Magistr Martin
        </Typography>
        <br />
        <br />
        <br />
        <Button variant="contained" color="primary" fullWidth onClick={() => (window.location.href = "/")}>
          Zpět na Magistr Martin rezervace eReceptu
        </Button>
        &nbsp; &nbsp; &nbsp;
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => (window.location.href = "https://magistrmartin.cz")}
        >
          Přejít do E-Shopu Magistr Martin
        </Button>
        <br />
        <br />
      </Container>
    </Container>
  );
}
